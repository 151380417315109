<template>
  <div class="container" id="container">
    <div v-if="language.includes('es')" class="login_form">
      <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
      <h1>Ingresa a tu Cuenta</h1>
      <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>
      <div class="input_area">
        <div class="txt_field">
          <input v-model="email" required/>
          <label> Correo Electrónico </label>
        </div>
        <div class="txt_field">
          <input v-model="password" type="password" required/>
          <label> Contraseña </label>
        </div>
        <button class="login_button" @click="login()">Ingresar</button>
        <div class="register">
          ¿No tienes cuenta? <router-link to="/registrarse">Registrarme</router-link>
        </div>
        <div class="register">
          Olvidaste tu contraseña? <div @click="cambiarContraseña" class="cambiarC">Cambiar Contraseña</div>
        </div>
      </div>
    </div>

    <div v-else class="login_form">
      <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
      <h1>Log in to your Account</h1>
      <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>
      <div class="input_area">
        <div class="txt_field">
          <input v-model="email" required/>
          <label> Email</label>
        </div>
        <div class="txt_field">
          <input v-model="password" type="password" required/>
          <label> Password </label>
        </div>
        <button class="login_button" @click="login()">Log In</button>
        <div class="register">
          Don't have an account? <router-link to="/registrarse">Request</router-link>
        </div>
        <div class="register">
          Did you forget the password? <div @click="cambiarContraseña" class="cambiarC">Change Password</div>
        </div>
      </div>
    </div>

  </div>
  <!-- Video con el logo de la empresa (solo visible al finalizar el proceso de autenticacion) -->
  <video id="video" class="videoLogo">
    <source src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2FLogin%20Video.mp4?alt=media&token=27f42141-6353-4e76-ade5-9f0c7450e62d" type="video/mp4">
  </video>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'


export default {
  data () {
    return {
      language: navigator.language
    }
  },
  mounted() {
    if (this.language.includes('es')) {
      document.title = 'Ingresar'
    } else {
      document.title = 'Log In'
    }
  },
  methods: {
    cambiarContraseña() {
      let pregunta
      let respuesta
      if (this.language.includes('es')) {
        pregunta = 'Ingrese su correo electrónico'
        respuesta = 'Se ha enviado un correo electrónico para cambiar su contraseña, cambiela y vuelva a intentarlo'
      } else {
        pregunta = 'Enter your email'
        respuesta = 'An email has been sent to change your password, change it and try again'
      }

      let email = prompt(pregunta)
      if (email) {
        let auth = getAuth()
        sendPasswordResetEmail(auth, email)
          .then(() => {
            alert(respuesta)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
}
</script>

<script setup>
const router = useRouter()
// Obtener los datos del template
const email = ref('')
const password = ref('')
const errorMessage = ref('')

const login = () => {
  const auth = getAuth()

  // Autenticar al usuario con el auth de Firebase
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((data) => {
      let video = document.getElementById('video')
      let container = document.getElementById('container')
      container.style.display = 'none'
      video.style.display = 'block'
      video.volume = 0.1
      video.playbackRate = 1
      video.play()
      video.onended = () => {
        router.push('/content')
      }
    })
    .catch((error) => {
      // En caso de no poder autenticar a usuario mostrar el motivo
      console.log(error.code)
      switch (error.code) {
        case 'auth/user-not-found':
          if (navigator.language.includes('es')) {
            errorMessage.value = 'Correo Electrónico Incorrecto'
          } else {
            errorMessage.value = 'Incorrect Email'
          }
          break
        case 'auth/wrong-password':
          if (navigator.language.includes('es')) {
            errorMessage.value = 'Contraseña Incorrecta'
          } else {
            errorMessage.value = 'Incorrect Password'
          }
          break
        default:
          if (navigator.language.includes('es')) {
            errorMessage.value = 'Correo Electrónico o Contraseña Inválidos'
          } else {
            errorMessage.value = 'Invalid Email or Password'
          }
          break
      }
    })
}
</script>

<style src="@/assets/css/login.css" scoped></style>
