<template>
    <div class="container">

        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Blanco.png" class="logo"></a>
        <h1 v-if="language.includes('es')">Viva la Ciencia</h1>  
        <h1 v-else>I Love Science</h1>

        <div v-if="language.includes('es')" class="texto">
            <p>Las ciencias naturales hacen parte de nuestro entorno. Los que se considera que es de gran utilidad dentro del aprendizaje, los temas en esta aplicación web son sobre el ser humano y su medio ambiente. Es muy útil en las primeras etapas ya que cuenta con un material interactivo y gráfico que se ajusta a las necesidades de los estudiantes. Se ha diseñado para trabajar por lecciones.</p>
            <p style="text-align: center;"><strong>La finalidad de esta aplicación web es apoyar diferentes campos disciplinares y personas que describe de la siguiente manera:</strong></p>
        </div>

        <div v-else class="texto">
            <p>Science is part of our environment, and it is considered an important topic in our lives. To learn more about science is useful in early learning years. In this Web Application, you will find interactive resources and fun illustrations to explain the importance of Science, you will find different lessons in here.</p>
            <p style="text-align: center;"><strong>The purpose of this web application is to support different disciplinary fields and people that it describes as follows:</strong></p>
        </div>

        <div>
            <button class="option_button" @click="mostrarOpcion(1)" :style="[botonActivo === 1 ? { 'background-color': '#25274F' } : '']" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Fboton1.png?alt=media&token=18daa9ae-0f00-4548-9ca0-e035c8ca4072');"></button>
            <button class="option_button" @click="mostrarOpcion(2)" :style="[botonActivo === 2 ? { 'background-color': '#25274F' } : '']" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Fboton2.png?alt=media&token=4dd20b6a-dcf3-4297-8609-c92ba3d60fc6');"></button>
            <button class="option_button" @click="mostrarOpcion(3)" :style="[botonActivo === 3 ? { 'background-color': '#25274F' } : '']" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Fboton3.png?alt=media&token=46c1ab41-ffe9-4394-b13c-db6f4835f7ad');"></button>
            <button class="option_button" @click="mostrarOpcion(4)" :style="[botonActivo === 4 ? { 'background-color': '#25274F' } : '']" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Fboton4.png?alt=media&token=9c89255d-5f79-4927-80a7-589ef2068709');"></button>
        </div>

        <div v-if="language.includes('es')">
            <RouterLink to="/registrarse">
                <button class="link_button">Registrarse</button>
            </RouterLink>
            <RouterLink to="/ingresar">
                <button class="link_button">Acceder</button>
            </RouterLink>
        </div>
        <div v-else>
            <RouterLink to="/registrarse">
                <button class="link_button">Register</button>
            </RouterLink>
            <RouterLink to="/ingresar">
                <button class="link_button">Log In</button>
            </RouterLink>
        </div>

        <Popup v-if="TogglePopup" v-on:metodo="cerrarOpcion" :texto="contenido" />

    </div>
    

</template>

<script>
import Popup from '@/components/Popup.vue'

export default {
    name: 'home',
    components: {
        Popup
    },
    mounted() {
        if (this.language.includes('es')) {
            document.title = 'Viva la Ciencia'
        } else {
            document.title = 'I Love Science'
        }
    },
    data () {
        return {
            language: navigator.language,
            TogglePopup: false,
            contenido: '',
            botonActivo: false
        }
    },
    methods: {
        mostrarOpcion(numero) {
            switch (numero) {
                case 1:
                    this.botonActivo = 1
                    if (this.language.includes('es')) {
                        this.contenido = 'Personas que aprenden nociones básicas de las ciencias naturales.'
                    } else {
                        this.contenido = 'People who learn basic notions of the natural sciences.'
                    }
                    break
                case 2:
                    this.botonActivo = 2
                    if (this.language.includes('es')) {
                        this.contenido = 'Investigadores de las ciencias puras.'
                    } else {
                        this.contenido = 'Researchers of the pure sciences.'
                    }
                    break
                case 3:
                    this.botonActivo = 3
                    if (this.language.includes('es')) {
                        this.contenido = 'Administradores pedagógicos y maestros de escuelas, colegios, institutos, universidades que necesitan un material adicional para sus currículos educativos con el fin que sus estudiantes aprendan sobre las ciencias naturales en Español.'
                    } else {
                        this.contenido = 'Pedagogical administrators and teachers of schools, colleges, institutes, universities who need additional material for their educational curricula in order for their students to learn about natural sciences in Spanish.'
                    }
                    break
                case 4: 
                    this.botonActivo = 4
                    if (this.language.includes('es')) {
                        this.contenido = 'Profesores y/o maestros que desean vincular en sus currículos la importancia que sus estudiantes aprendan las ciencias naturales, proyectando a sus estudiantes a generar una mentalidad investigativa y el reconocimiento del ser humano y el entorno.'
                    } else {
                        this.contenido = 'Professors and/or teachers who wish to link in their curricula the importance that their students learn Science, projecting their students to generate an investigative mentality and the recognition of the human being and the environment.'
                    }
                    break
                default:
                    this.contenido = ''
                    break
            }
            this.TogglePopup = true
        },
        cerrarOpcion() {
            this.TogglePopup = false
            this.botonActivo = false
        }
    }
}
</script>

<style src="@/assets/css/home.css" scoped></style>
