import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvBdsZZ7So8LNkm9GRgQKg6IV106feYi8",
  authDomain: "viva-la-ciencia.firebaseapp.com",
  projectId: "viva-la-ciencia",
  storageBucket: "viva-la-ciencia.appspot.com",
  messagingSenderId: "6600895630",
  appId: "1:6600895630:web:e884f81f61b3c186392ddf",
  measurementId: "G-D1YZYR6JJZ"
}

// Initialize Firebase
initializeApp(firebaseConfig)
const db = getFirestore()

// Create Vue app
const app = createApp(App)
app.use(router)
app.mount('#app')

export {
  db
}