<template>
  <div class="container" id="container">
    <div v-if="language.includes('es')" class="login_form">
      <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
      <h1>Crear una Cuenta</h1>

      <div v-if="Message === 'Password'" :style="{color: MessageColor}">
        La contraseña debe tener al menos:
        <ul>
            <li>6 Caracteres</li>
            <li>1 Caracter NO alfanumérico (!@#$%^&*)</li>
        </ul>
      </div>
      <p v-else-if="Message" :style="{color: MessageColor}">{{ Message }}</p>

      <div class="input_area">
        <div class="txt_field">
          <input id="nombre" required/>
          <label> Nombre </label>
        </div>
        <div class="options_field">
            <label> Nivel Educativo </label>
            <select name="nivel_educativo" id="nivel_educativo">
                <option value="">Escoge una opción...</option>
                <option value="primaria">Educación Primaria</option>
                <option value="secundaria">Educación Secundaria</option>
                <option value="tecnica">Educación Técnica</option>
                <option value="superior">Educación Superior (Universitaria o Equivalente)</option>
                <option value="posgrado">Posgrado (Maestría o Doctorado)</option>
            </select>
        </div>
        <div class="options_field">
            <label> Ocupación </label>
            <select name="ocupacion" id="ocupacion">
                <option value="">Escoge una opción...</option>
                <option value="estudiante">Estudiante</option>
                <option value="profesor">Profesor</option>
                <option value="directivo">Directivo</option>
                <option value="otro">Otro</option>
            </select>
        </div>
        <div class="txt_field">
            <input id="motivo" required/>
            <label> Motivo </label>
        </div>
        <div class="txt_field">
          <input id="email" required/>
          <label> Correo Electrónico </label>
        </div>
        <div class="txt_field">
          <input id="password" type="password" required/>
          <label> Contraseña </label>
        </div>
        <div id="recaptcha-main" class="g-recaptcha" data-sitekey="6LfofX0lAAAAAFYCydfDqGm8OiYT_49hIrpqWfdN"></div>
        <div class="terminos">
            <div class="enlaces">
                <a href="https://es.funifelt.com/terms-and-conditions" target="_blank">Términos y Condiciones</a>
                <a href="https://es.funifelt.com/privacy-statement" target="_blank">Privacidad y Políticas</a>
            </div>
            <div class="caja">
                <input type="checkbox" v-model="checkedTerms" class="defaultCheckbox"/>
                <label for="checkbox"> Estoy de acuerdo con los términos y condiciones y las políticas de privacidad</label>
            </div>
        </div>
        <button class="login_button" @click="solicitar()"> Registrarse </button>
        <div class="register">
          ¿Ya tienes cuenta? <router-link to="/ingresar"> Ingresar </router-link>
        </div>
      </div>
    </div>

    <div v-else class="login_form">
      <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
      <h1>Create an Account</h1>

      <div v-if="Message === 'Password'" :style="{color: MessageColor}">
        The password must have at least:
        <ul>
            <li>6 Characters</li>
            <li>1 NO Alphanumeric Character (!@#$%^&*)</li>
        </ul>
      </div>
      <p v-else-if="Message" :style="{color: MessageColor}">{{ Message }}</p>

      <div class="input_area">
        <div class="txt_field">
          <input id="nombre" required/>
          <label> Name </label>
        </div>
        <div class="options_field">
            <label> Education Level </label>
            <select name="nivel_educativo" id="nivel_educativo">
                <option value="">Choose an option...</option>
                <option value="primaria">Primary Education</option>
                <option value="secundaria">Secondary Education</option>
                <option value="tecnica">Technical Education</option>
                <option value="superior">Higher Education (University or Equivalent)</option>
                <option value="posgrado">Postgraduate (Master's degree or PhD.)</option>
            </select>
        </div>
        <div class="options_field">
            <label> Occupation </label>
            <select name="ocupacion" id="ocupacion">
                <option value="">Choose an option...</option>
                <option value="estudiante">Student</option>
                <option value="profesor">Teacher</option>
                <option value="directivo">Management</option>
                <option value="otro">Other</option>
            </select>
        </div>
        <div class="txt_field">
            <input id="motivo" required/>
            <label> Reason </label>
        </div>
        <div class="txt_field">
          <input id="email" required/>
          <label> Email </label>
        </div>
        <div class="txt_field">
          <input id="password" type="password" required/>
          <label> Password </label>
        </div>
        <div id="recaptcha-main" class="g-recaptcha" data-sitekey="6LfofX0lAAAAAFYCydfDqGm8OiYT_49hIrpqWfdN"></div>
        <div class="terminos">
            <div class="enlaces">
                <a href="https://www.funifelt.com/terms-and-conditions" target="_blank">Terms and Conditions</a>
                <a href="https://www.funifelt.com/privacy-statement" target="_blank">Privacy Policies</a>
            </div>
            <div class="caja">
                <input type="checkbox" v-model="checkedTerms" class="defaultCheckbox"/>
                <label for="checkbox"> I have read and agree with the terms and conditions</label>
            </div>
        </div>
        <button class="login_button" @click="solicitar()"> Register </button>
        <div class="register">
          Already have an account? <router-link to="/ingresar"> Log In </router-link>
        </div>
      </div>
    </div>

    <Popup v-if="TogglePopup" v-on:metodo="cerrarOpcion" :texto="Message"/>

  </div>
</template>

<script>
import { db } from '@/main.js'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'
import Popup from '@/components/Popup.vue'

export default {
    name: 'Registro',
    components: {
        Popup
    },
    data () {
        return {
            language: navigator.language,
            Message: '',
            MessageColor: 'green',
            TogglePopup: false,
            checkedTerms: false
        }
    },
    mounted() {
        if (this.language.includes('es')) {
            document.title = 'Registrarse'
        } else {
            document.title = 'Register'
        }
    },
    created () {
        this.$nextTick(function () {
            grecaptcha.render('recaptcha-main')
        }).catch(error => {
            console.log('Captcha already loaded')
        })
    },
    methods: {
        async solicitar () {
            this.MessageColor = 'yellow'
            if (this.language.includes('es')) {
                this.Message = 'Verificando...'
            } else {
                this.Message = 'Verifying...'
            }


            // Scroll hasta el inicio de la pagina para mostrar el mensaje de verificacion/error
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })


            // Obtener la ubicacion del usuario
            const promise = this.getUserCountry()
            promise.then(location => {
                // La promesa se resolvió correctamente, se obtuvo la ubicación del usuario
                console.log('Ubicación del usuario:', location)
                return location
            })
            .catch(error => {
                // La promesa fue rechazada, ocurrió un error
                console.error('Error:', error)
                this.MessageColor = 'red'
                if (this.language.includes('es')) {
                    this.Message = 'Activa la localización y vuelve a intentarlo'
                } else {
                    this.Message = 'Activate the location and try again'
                }
                return
            })
            let ubicacion = await promise


            // Obtener los datos del formulario
            const email = document.getElementById('email').value
            const password = document.getElementById('password').value
            const nombre = document.getElementById('nombre').value
            const motivo = document.getElementById('motivo').value
            const nivel_educativo = document.getElementById('nivel_educativo').value
            const ocupacion = document.getElementById('ocupacion').value

            // Verificar que todos los campos esten llenos
            if (nombre == '' || motivo == '' || nivel_educativo == '' || ocupacion == '') {
                this.MessageColor = 'red'
                if (this.language.includes('es')) {
                    this.Message = 'Por favor llena todos los campos'
                } else {
                    this.Message = 'Please fill in all fields'
                }
                return
            }

            // Verificar que el correo sea valido
            if (!(this.emailValido(email))) {
                this.MessageColor = 'red'
                if (this.language.includes('es')) {
                    this.Message = 'Por favor ingresa un correo válido'
                } else {
                    this.Message = 'Please enter a valid email'
                }
                return
            }

            // Verificar que la contraseña cumpla con los requisitos minimos de seguridad
            if (!(this.contraseñaValidaPrueba(password))) {
                this.MessageColor = 'red'
                this.Message = 'Password'
                return
            }

            // Verificar el captcha
            let capthcaResponse = grecaptcha.getResponse()
            if (capthcaResponse.length == 0) {
                this.MessageColor = 'red'
                if (this.language.includes('es')) {
                    this.Message = 'Por favor verifica que no eres un robot'
                } else {
                    this.Message = 'Please verify that you are not a robot'
                }
                return
            }

            // Verificar que los terminos y condiciones esten aceptados
            let terminos = this.checkedTerms
            if (!terminos) {
                this.MessageColor = 'red'
                if (this.language.includes('es')) {
                    this.Message = 'Por favor lea y acepte los terminos y condiciones'
                } else {
                    this.Message = 'Please read and accept the terms and conditions'
                }
                return
            }


            // Crear la cuenta en firebase
            let accountCreated = await this.createAccount({email: email, password: password})
            if (accountCreated === false) {
                this.MessageColor = 'red'
                if (this.language.includes('es')) {
                    this.Message = 'No se pudo crear la cuenta, intente más tarde'
                } else {
                    this.Message = 'Could not create account, try again later'
                }
                return
            }
            // Verificar que la cuenta no exista
            if (accountCreated === null) {
                return
            }


            // Obtener la fecha actual
            const date = new Date()
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let currentDate = `${year}-${month}-${day}`


            // Guardar el lenguaje que se le asigno al usuario
            let idioma_navegador
            if (this.language.includes('es')) {
                idioma_navegador = 'español'
            } else {
                idioma_navegador = 'ingles'
            }


            // Guardar los datos del usuario en la coleccion de usuarios
            if (ubicacion) {
                setDoc(doc(db, 'Usuarios', email), {
                    UID: accountCreated,
                    email: email,
                    nombre: nombre,
                    ocupacion: ocupacion,
                    motivo: motivo,
                    ubicacion: ubicacion,
                    nivel_educativo: nivel_educativo,
                    fecha_creacion: currentDate,
                    idioma_navegador: idioma_navegador
                })
                .then(() => {
                    this.MessageColor = 'green'
                    if (this.language.includes('es')) {
                        this.Message = 'Solicitud Realizada Correctamente'
                    } else {
                        this.Message = 'Request Successfully Completed'
                    }

                    // redirigir al usuario a la pagina de inicio
                    setTimeout(() => {
                        this.$router.push('/content')
                    }, 1000)
                })
                .catch((error) => {
                    console.error(error)
                    this.MessageColor = 'red'
                    if (this.language.includes('es')) {
                        this.Message = 'No se pudo realizar la solicitud, intente más tarde'
                    } else {
                        this.Message = 'Could not complete the request, try again later'
                    }
                })
            } else {
                console.log('no se pudo obtener la ubicacion del usuario')
                setDoc(doc(db, 'Usuarios', email), {
                    UID: accountCreated,
                    email: email,
                    nombre: nombre,
                    ocupacion: ocupacion,
                    motivo: motivo,
                    ubicacion: 'error',
                    nivel_educativo: nivel_educativo,
                    fecha_creacion: currentDate,
                    idioma_navegador: idioma_navegador
                })
            }
        },
        // Funcion para obtener mas datos a partir de la latitud y longitud del usuario
        async getUserCountry () { 
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const { latitude, longitude } = position.coords
                        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=es`

                        fetch(url)
                        .then(res => res.json())
                        .then(data => {
                            const location = data.address
                            resolve(location)
                        })
                        .catch(() => {
                            reject("Error getting user country")
                        })
                    },
                    error => {
                        reject(error)
                    }
                )
            })
        },
        // Funcion para validar el formato del email
        emailValido (email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return regex.test(email)
        },
        // Funcion para validar que la contraseña cumpla con los requisitos de seguridad
        contraseñaValida (contraseña) {
            /* 
                La contraseña debe tener al menos:
                - 6 caracteres
                - 1 mayuscula
                - 1 minuscula
                - 1 numero
                - 1 caracter especial (!@#$%^&*)
            */
            const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/
            return regex.test(contraseña)
        },

        
        // Contraseña valida en la version test 
        contraseñaValidaPrueba (password) {
            /* 
                La contraseña debe tener al menos:
                - 6 caracteres
                - 1 caracter no alfanumerico (!@#$%^&*)
            */
            // Verifica la longitud mínima
            if (password.length < 6) {
                return false
            }

            // Verifica la presencia de caracteres no alfanuméricos
            const hasNonAlphaNumericCharacter = /[^\w]/.test(password);
            if (!hasNonAlphaNumericCharacter) {
                return false
            }

            // La contraseña cumple con todos los requisitos
            return true
        },


        // Funcion para cerrar el popup
        cerrarOpcion () {
            this.TogglePopup = false
        },
        
        // Funcion para crear una cuenta en firebase
        async createAccount (solicitud) {
            try {
                const auth = getAuth()
                const userCredential = await createUserWithEmailAndPassword(auth, solicitud.email, solicitud.password)
                const user = userCredential.user
                return user.uid
            } catch (error) {
                const errorMessage = error.message
                if (errorMessage == 'Firebase: Error (auth/email-already-in-use).') {
                    if (this.language.includes('es')) {
                        console.log('Ya existe una cuenta con este correo electrónico...')
                        this.Message = 'Ya existe una cuenta con este correo electrónico'
                    } else {
                        this.Message = 'There is already an account with this email'
                    }
                    return null
                }
                return false
            }
        }
    }
}
</script>

<style src="@/assets/css/login.css" scoped></style>
