<template>
    <div class="popup" @click="cerrar()">
        <div class="popup-inner">   
            <span v-html="texto"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['texto'],
    methods: {
        cerrar () {
            this.$emit('metodo')
        }
    }
}
</script>

<style lang="scss" scoped>
.popup {
    // Parte externa del popup 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    // Popup
    .popup-inner {
        border-radius: 30px;
        background-color: white;
        width: 70%;
        padding: 30px;
        font-size: 24pt;
        font-family: Cambay-Regular;
        color: #3B5CAB;   
        position: relative;
    }
}

// Resoluciones menores a 1000px
@media (max-width: 1000px) {
    .popup {
        .popup-inner {
            width: 90%;
            font-size: 18pt;
        }
    }
}
</style>
