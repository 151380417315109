<template>
  <!-- Barra de Navegacion -->
  <nav class="navbar">
    <div class="brand-title">
      <img src="@/assets/images/funifelt.png"> 
      <router-link to="/content">
        <h3 v-if="language.includes('es')">Viva la Ciencia</h3>
        <h3 v-else>I Love Science</h3>
      </router-link>
    </div>
    <a :style="[isLoggedIn ? '' : { 'display': 'none' }]" href="#" class="toggle-button">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </a>

    <div class="navbar-links">
      <ul v-if="language.includes('es')">
        <li> <router-link to="/content" v-if="isLoggedIn" :style="[$route.name === 'content' ? { 'background-color': '#3B5CAB' } : '']" > Lecciones </router-link> </li>
        <li> <router-link to="/perfil" v-if="isLoggedIn" :style="[$route.name === 'perfil' ? { 'background-color': '#3B5CAB' } : '']" > Perfil </router-link> </li>
      </ul>
      <ul v-else>
        <li> <router-link to="/content" v-if="isLoggedIn" :style="[$route.name === 'content' ? { 'background-color': '#3B5CAB' } : '']" > Lessons </router-link> </li>
        <li> <router-link to="/perfil" v-if="isLoggedIn" :style="[$route.name === 'perfil' ? { 'background-color': '#3B5CAB' } : '']" > Profile </router-link> </li>
        
      </ul>
    </div>
  </nav>

  <div id="page-container">
    <!-- Contenido de la vista/componente -->
    <div id="content-wrap" >
      <router-view/>
    </div>
    <!-- Footer -->
    <footer v-if="language.includes('es')" id="footer">
      <div class="footer-container">

          <div class="categories">
            <h2 @click="showCategory(0)">
              Visítanos 
              <i v-if="isMobile && !isOpen1" class="material-icons">&#xe5c5;</i> 
              <i v-else-if="isMobile && isOpen1" class="material-icons">&#xe5c7;</i>
            </h2>
            <div class="category-options">
              <ul>
                <li> <a href="https://www.funifelt.com/" target="_blank"> FUNIFELT Sitio Web </a> </li>
                <li> <a href="https://relatos-prueba.web.app/" target="_blank"> Relatos, Mitos y Leyendas </a> </li>
                <li> <a href="https://colombia-prueba.web.app/" target="_blank"> Regiones de Colombia </a> </li>
                <li> <a href="https://play.google.com/store/apps/dev?id=5041211756149277759" target="_blank"> Apps de Idiomas </a> </li>
                <li> <a href="https://jergas-prueba.web.app/" target="_blank"> Jergas </a> </li>
                <li> <a href="https://spanish-prueba.web.app/" target="_blank"> Español Básico </a> </li>
                <li> <a href="https://unity--language-game-funifelt.web.app/" target="_blank"> Juegos Online </a> </li>
                <li> <a href="https://viva-la-ciencia-prueba.web.app/" target="_blank"> Viva la Ciencia </a> </li>
              </ul>
            </div>
          </div>

          <div class="categories" >
            <h2 @click="showCategory(1)">
              Sobre Nosotros
              <i v-if="isMobile && !isOpen2" class="material-icons">&#xe5c5;</i> 
              <i v-else-if="isMobile && isOpen2" class="material-icons">&#xe5c7;</i>
            </h2>
            <div class="category-options">
              <ul>
                <li> <router-link to="/advisors">Asesores</router-link> </li>
                <li> <router-link to="/research">Investigación</router-link> </li>
                <li> <router-link to="/contact">Contacto</router-link> </li>
                <li> <router-link to="/help">Ayuda</router-link> </li>
              </ul>
            </div>
          </div>

      </div>

      <div class="social-links">
        <a href="https://www.facebook.com/funifelt/" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-facebook.png?alt=media&token=141a1cab-5c53-4a78-af88-76acc3761569"> </a>
        <a href="https://x.com/funifelt/" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/funifelt--ovas.appspot.com/o/iconos%2Fx.png?alt=media&token=bde58681-d02f-424b-bac9-d5a880978fad"> </a>
        <a href="https://www.youtube.com/channel/UCbz3YP4xOUUnGRQgQlhsEsQ" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-youtube.png?alt=media&token=46af4c2c-933c-4588-b623-d365c68eeffc"> </a>
        <a href="https://www.instagram.com/funifelt/" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-instagram.png?alt=media&token=38dacecd-7b81-4027-859c-0abe4d417c0b"> </a>
        <a href="https://www.linkedin.com/company/funifelt" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-linkedin.png?alt=media&token=944dc1ae-c14c-4131-8287-a771193ab86c"> </a>
        <a href="https://open.spotify.com/show/4uu0KBkcsE1p9l9TH0eHBv" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-spotify.png?alt=media&token=4821f5f8-ecc8-49b4-a151-c524e6a0f1f8"> </a>
      </div>
      <div class="rights">
        &copy; FUNIFELT 2023 - Todos los derechos reservados
      </div>
    </footer>

    <footer v-else id="footer">
      <div class="footer-container">

          <div class="categories" >
            <h2 @click="showCategory(0)">
              Visit Us
              <i v-if="isMobile && !isOpen1" class="material-icons">&#xe5c5;</i> 
              <i v-else-if="isMobile && isOpen1" class="material-icons">&#xe5c7;</i>
            </h2>
            <div class="category-options">
              <ul>
                <li> <a href="https://www.funifelt.com/" target="_blank"> FUNIFELT Website </a> </li>
                <li> <a href="https://relatos-prueba.web.app/" target="_blank"> Spanish Folktales </a> </li>
                <li> <a href="https://colombia-prueba.web.app/" target="_blank"> Colombian Regions </a> </li>
                <li> <a href="https://play.google.com/store/apps/dev?id=5041211756149277759" target="_blank"> Language Apps </a> </li>
                <li> <a href="https://jergas-prueba.web.app/" target="_blank"> Spanish Slangs </a> </li>
                <li> <a href="https://spanish-prueba.web.app/" target="_blank"> Elementary Spanish </a> </li>
                <li> <a href="https://unity--language-game-funifelt.web.app/" target="_blank"> Language Games </a> </li>
                <li> <a href="https://viva-la-ciencia-prueba.web.app/" target="_blank"> I Love Science </a> </li>
              </ul>
            </div>
          </div>

          <div class="categories" >
            <h2 @click="showCategory(1)">
              About Us
              <i v-if="isMobile && !isOpen2" class="material-icons">&#xe5c5;</i> 
              <i v-else-if="isMobile && isOpen2" class="material-icons">&#xe5c7;</i>
            </h2>
            <div class="category-options">
              <ul>
                <li> <router-link to="/advisors">Advisors</router-link> </li>
                <li> <router-link to="/research">Research</router-link> </li>
                <li> <router-link to="/contact">Contact Us</router-link> </li>
                <li> <router-link to="/help">Help</router-link> </li>
              </ul>
            </div>
          </div>

      </div>

      <div class="social-links">
        <a href="https://www.facebook.com/funifelt/" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-facebook.png?alt=media&token=141a1cab-5c53-4a78-af88-76acc3761569"> </a>
        <a href="https://x.com/funifelt/" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/funifelt--ovas.appspot.com/o/iconos%2Fx.png?alt=media&token=bde58681-d02f-424b-bac9-d5a880978fad"> </a>
        <a href="https://www.youtube.com/channel/UCbz3YP4xOUUnGRQgQlhsEsQ" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-youtube.png?alt=media&token=46af4c2c-933c-4588-b623-d365c68eeffc"> </a>
        <a href="https://www.instagram.com/funifelt/" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-instagram.png?alt=media&token=38dacecd-7b81-4027-859c-0abe4d417c0b"> </a>
        <a href="https://www.linkedin.com/company/funifelt" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-linkedin.png?alt=media&token=944dc1ae-c14c-4131-8287-a771193ab86c"> </a>
        <a href="https://open.spotify.com/show/4uu0KBkcsE1p9l9TH0eHBv" target="_blank"> <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-spotify.png?alt=media&token=4821f5f8-ecc8-49b4-a151-c524e6a0f1f8"> </a>
      </div>
      <div class="rights">
        &copy; FUNIFELT 2023 - All rights reserved
      </div>
    </footer>

    <!-- Notificacion de que se utilizan cookies -->
    <Cookies v-if="ToggleCookies" v-on:aceptado="cerrarCookies"/>

  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { setDoc, doc } from 'firebase/firestore'
import { db } from '@/main.js'
import Cookies from '@/components/cookies.vue'

export default {
  components: {
    Cookies
  },
  setup() {
    const isLoggedIn = ref(false)
    let auth
    onMounted(() => {
      // Funcion para conocer si el usuario esta autenticado
      auth = getAuth()
      onAuthStateChanged(auth, (user) => {
        if (user) {
          isLoggedIn.value = true
        } else {
          isLoggedIn.value = false
        }
      })

      // Barra de navegacion
      const toggle_button = document.getElementsByClassName("toggle-button")[0]
      const navbar_links = document.getElementsByClassName("navbar-links")[0]
      
      // Funcion para minimizar la barra de navegacion luego de hacer click en alguna opcion (resoluciones menores a 1000px)
      navbar_links.querySelectorAll('li').forEach((link) => { 
        link.addEventListener("click", () => {
          navbar_links.classList.toggle("active")
        })
      })
      // Funcion para plegar/desplegar la barra de navegacion luego de hacer click en el icono (resoluciones menores a 1000px)
      toggle_button.addEventListener("click", () => {
        navbar_links.classList.toggle("active")
      })
    })

    return {
      isLoggedIn
    }
  },
  data () {
    return {
      language: navigator.language,
      isMobile: window.innerWidth < 1000,
      isOpen1: false,
      isOpen2: false,

      // Notificacion de uso de cookies
      ToggleCookies: false,
      notificacionCookies: localStorage.getItem('notificacionCookies'),

      // Datos de navegacion
      randomSessionID: null,
      inicioSesion: null,
      routeHistory: []
    }
  },
  mounted () {
    // Mostrar notificacion de uso de cookies si el usuario no la ha visto
    if (!this.notificacionCookies) {
      this.ToggleCookies = true
    }

    // Generar un ID aleatorio para la sesion de navegacion
    this.randomSessionID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

    // Guardar el tiempo de inicio de sesion
    this.inicioSesion = new Date()

    // Crear el documento de la sesion en la base de datos
    // Si existe usuario autenticado, se guarda el ID del usuario
    try {
      setDoc(doc(db, 'SesionNavegacion', this.randomSessionID), {
        userID: getAuth().currentUser.uid,
        inicio: this.inicioSesion,
        final: null,
        total: null,
        historialDeNavegacion: ['home']
      })
    } catch (error) {
      setDoc(doc(db, 'SesionNavegacion', this.randomSessionID), {
        userID: 'No autenticado',
        inicio: this.inicioSesion,
        final: null,
        total: null,
        historialDeNavegacion: ['home']
      })
    }


    // Funcion para evitar que el usuario salga de la pagina (preguntar antes de salir)
    let data = this
    window.addEventListener('beforeunload', function (e) {

      // Conocer el tiempo de sesion del usuario
      let tiempoTotal = data.calcularTiempoTotal()

      // Actualizar los datos en la base de datos
      try {
        setDoc(doc(db, 'SesionNavegacion', data.randomSessionID), {
          userID: getAuth().currentUser.uid,
          final: new Date(),
          total: tiempoTotal,
          historialDeNavegacion: data.routeHistory
        }, { merge: true })
      } catch (error) {
        setDoc(doc(db, 'SesionNavegacion', data.randomSessionID), {
          final: new Date(),
          total: tiempoTotal,
          historialDeNavegacion: data.routeHistory
        }, { merge: true })
      }

      // Preguntar antes de cerrar la pagina
      e.preventDefault()
      e.returnValue = ''
    })



  },
  watch: {
    $route (to, from) {
      // Esconder todos los elementos del footer
      let elements = document.getElementsByClassName("category-options")
      for (let i = 0; i < elements.length; i++) {
        elements[i].className = 'category-options'
      }
      
      // Ajustar el padding del contenido para que no se superponga con el footer
      document.getElementById("content-wrap").style.paddingBottom = ((document.getElementById("footer").offsetHeight) + 150) + 'px'


      // Guardar el historial de navegacion
      let route = to.name
      if (route.includes('undefined')) {
        route = route.replace('undefined', '/')
      }
      this.routeHistory.push(route)

      // Conocer el tiempo de sesion del usuario
      let tiempoTotal = this.calcularTiempoTotal()

      // Actualizar los datos en la base de datos
      try {
        setDoc(doc(db, 'SesionNavegacion', this.randomSessionID), {
          userID: getAuth().currentUser.uid,
          final: new Date(),
          total: tiempoTotal,
          historialDeNavegacion: this.routeHistory
        }, { merge: true })
      } catch (error) {
        setDoc(doc(db, 'SesionNavegacion', this.randomSessionID), {
          final: new Date(),
          total: tiempoTotal,
          historialDeNavegacion: this.routeHistory
        }, { merge: true })
      }

    }
  },
  methods: {
    showCategory (k) {

      // Si la resolucion es mayor a 1000px, no se ejecuta la funcion
      if (!this.isMobile) {
        return
      }

      let elements = document.getElementsByClassName("category-options")
      elements[k].classList.toggle("active")
      for (let i = 0; i < elements.length; i++) {
        if (i != k) {
          elements[i].className = "category-options"
        }
      }
      document.getElementById("content-wrap").style.paddingBottom = ((document.getElementById("footer").offsetHeight) + 90) + 'px'
      
      // Cambiar el icono de la categoria abierta o cerrada
      switch (k) {
        case 0: 
          if (this.isOpen1) {
            this.isOpen1 = false
            this.isOpen2 = false
          } else {
            this.isOpen1 = true
            this.isOpen2 = false
          }
          break

        case 1:
          if (this.isOpen2) {
            this.isOpen2 = false
            this.isOpen1 = false
          } else {
            this.isOpen2 = true
            this.isOpen1 = false
          }
          break 
      }
    },
    cerrarCookies () {
      localStorage.setItem('notificacionCookies', true)
      this.ToggleCookies = false
    },
    calcularTiempoTotal () {
      const terminacionSesion = new Date()
      let diff = terminacionSesion.getTime() - this.inicioSesion.getTime()
      let segundos = Math.floor(diff / 1000) % 60
      let minutos = Math.floor(diff / (1000 * 60)) % 60
      let horas = Math.floor(diff / (1000 * 60 * 60))
    
      return `${horas}:${minutos}:${segundos}`
    }
  }
}
</script>

<style src="@/assets/css/app.css"></style>
