<template>
    <div v-if="language.includes('es')">

        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1> Preguntas Frecuentes </h1>  

        <input id="busqueda" v-on:keyup="buscar" class="barraDeBusqueda" placeholder="¿Cuál es tu pregunta?" type="text" autocomplete="off"/>

        <div class="pregunta">
            <h3> ¿Cómo solicitar una cuenta? </h3>
            <p> 
                El acceso es privado, por lo que solo se da acceso a usuarios autorizados directamente por Funifelt. Para solicitar acceso debes ingresar a la página de <RouterLink to="/registrarse"><strong>registro</strong></RouterLink> y llenar todos los campos del formulario, pasar el recaptcha y permitir acceso a la ubicación en el navegador.
            </p>
        </div>

        <div class="pregunta">
            <h3> ¿Cuáles son los requisitos mínimos de la contraseña? </h3>
            <p> 
                Para solicitar una cuenta o para cambiar la contraseña de una cuenta existente, debes ingresar una contraseña que cumpla con los siguientes requisitos:
                <ul>
                    <li>6 caracteres</li>
                    <li>1 mayúscula</li>
                    <li>1 minúscula</li>
                    <li>1 número</li>
                    <li>1 carácter especial (!@#$%^&amp;*)</li>
                </ul>
            </p>
        </div>

        <div class="pregunta">
            <h3> ¿Cómo ingresar a la plataforma? </h3>
            <p> 
                Una vez Funifelt le ha dado acceso, puede ingresar a la plataforma desde la página de <RouterLink to="/ingresar"><strong>ingreso</strong></RouterLink> ingresando el correo y la contraseña asignados.
            </p>
        </div>

        <div class="pregunta">
            <h3> ¿Cómo cierrar sesión? </h3>
            <p> 
                Para cerrar sesión debes ingresar a la página de <RouterLink to="/perfil"><strong>perfil</strong></RouterLink> y hacer clic en el botón de cerrar sesión.
            </p>
        </div>

        <div class="pregunta">
            <h3> ¿Cómo cambiar la contraseña? </h3>
            <p>
                Para cambiar la contraseña de tu cuenta debes ingresar a la página de <RouterLink to="/perfil"><strong>perfil</strong></RouterLink> e ingresar la nueva contraseña y confirmarla (la contraseña nueva también tiene que cumplir con los requisitos mínimos de seguridad), luego debes hacer clic en el botón de cambiar contraseña.
            </p>
        </div>

        <div class="pregunta">
            <h3> ¿Como buscar un relato por su título? </h3>
            <p>
                Para buscar un relato en específico por su título debes ingresar a la página de <RouterLink to="/busqueda"><strong>búsqueda de relatos</strong></RouterLink> y escribir el título en la barra de búsqueda, no tienes que saber el nombre exacto, solo con escribir palabras del mismo obtendrás relatos cuyo título coincida con tu búsqueda.
            </p>
        </div>

        <div class="pregunta">
            <h3> ¿Para qué se creó este objeto virtual de aprendizaje (OVA)? </h3>
            <p>
                Se creó para que escuches y leas relatos de latinoamericanos que han pasado por varias generaciones y te ayudaran a comprender el estilo de vida y experiencias de vida de un gran porcentaje de latinoamericanos.
            </p>
        </div>

        <div class="pregunta">
            <h3> ¿A quiénes beneficia este objeto virtual de aprendizaje (OVA)? </h3>
            <p> 
                Este objeto virtual de aprendizaje esta dirijido a:
                <ul>
                    <li>Niños y adultos que aprenden español como lengua extranjera.</li>
                    <li>Investigadores y lingüistas que quieren identificar los diferentes acentos, entonación, expresiones idiomáticas y uso general de la lengua de los hablantes nativos.</li>
                    <li>Antropólogos e historiadores que necesitan analizar ideologías y aspectos culturales de algunos de los hablantes latinoamericanos que se encuentran inmersos en sus relatos.</li>
                    <li>Hablantes de lengua castellana (español) que están aprendiendo a leer y a escribir el idioma.</li>
                    <li>Administradores pedagógicos y maestros de escuelas, colegios, institutos, universidades que necesitan un material adicional para sus currículos educativos con el fin que sus estudiantes aprendan sobre la cultura latinoamericana y uso de la lengua.</li>
                    <li>Psicólogos, psicopedagogos y terapistas del lenguaje que estudian sobre los comportamientos del ser humano de acuerdo a su cultura, el uso y pronunciación de las palabras y el impacto de los relatos en el aprendizaje.</li>
                    <li>Profesores y/o maestros que desean vincular en sus currículos la importancia que sus estudiantes aprendan diversos tipos de textos como son: ciencia ficción, cuentos fantásticos de animales, fantasía, ficción histórica y realista, mitos, leyendas, narrativa personal, textos informativos y de procedimientos.</li>
                </ul>
            </p>
        </div>

    </div>

    <div v-else>
        <!-- English version -->
        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1> Frequently Asked Questions </h1>  

        <input id="busqueda" v-on:keyup="buscar" class="barraDeBusqueda" placeholder="What's your question?" type="text" autocomplete="off"/>

        <div class="pregunta">
            <h3>How to request an account?</h3>
            <p>
                Access is private, so it is only granted to authorized users directly by Funifelt. To request access, you must go to the <RouterLink to="/registrarse"><strong>registration</strong></RouterLink> page and fill out all the fields in the form, pass the recaptcha, and allow access to location in the browser.
            </p>
        </div>

        <div class="pregunta">
            <h3>What are the minimum password requirements?</h3>
            <p>
                To request an account or change the password for an existing account, you must enter a password that meets the following requirements:
                <ul>
                    <li>6 characters</li>
                    <li>1 uppercase letter</li>
                    <li>1 lowercase letter</li>
                    <li>1 number</li>
                    <li>1 special character (!@#$%^&amp;*)</li>
                </ul>
            </p>
        </div>

        <div class="pregunta">
            <h3>How to log in to the platform?</h3>
            <p>
                Once FUNIFELT has granted you access, you can log in to the platform from the <RouterLink to="/ingresar"><strong>login</strong></RouterLink> page by entering the assigned email and password.
            </p>
        </div>

        <div class="pregunta">
            <h3>How to log out?</h3>
            <p>
                To log out, you need to go to the <RouterLink to="/perfil"><strong>profile</strong></RouterLink> page and click the logout button.
            </p>
        </div>

        <div class="pregunta">
            <h3>How to change the password?</h3>
            <p>
                To change the password for your account, you need to go to the <RouterLink to="/perfil"><strong>profile</strong></RouterLink> page, enter the new password and confirm it (the new password must also meet the minimum security requirements), then click the change password button.
            </p>
        </div>

        <div class="pregunta">
            <h3>How to search for a story by its title?</h3>
            <p>
                To search for a specific story by its title, you need to go to the <RouterLink to="/buscar"><strong>story search</strong></RouterLink> page and enter the title in the search bar. You don't need to know the exact name, just by typing words related to it, you will get stories whose titles match your search.
            </p>
        </div>

        <div class="pregunta">
            <h3>Why was this virtual learning object (VLO) created?</h3>
            <p>
                It was created so you can listen to and read stories from Latin Americans that have been passed down through several generations. They will help you understand the lifestyle and life experiences of a large percentage of Latin Americans.
            </p>
        </div>

        <div class="pregunta">
            <h3>Who benefits from this virtual learning object (VLO)?</h3>
            <p>
                This virtual learning object is aimed at:
                <ul>
                    <li>Children and adults learning Spanish as a foreign language.</li>
                    <li>Researchers and linguists who want to identify different accents, intonation, idiomatic expressions, and general language use by native speakers.</li>
                    <li>Anthropologists and historians who need to analyze ideologies and cultural aspects of some of the Latin American speakers immersed in their stories.</li>
                    <li>Spanish-speaking individuals learning to read and write the language.</li>
                    <li>Educational administrators and teachers in schools, colleges, institutes, and universities who need additional material for their educational curricula to help their students learn about Latin American culture and language use.</li>
                    <li>Psychologists, psychoeducators, and language therapists studying human behavior based on culture, word usage, pronunciation, and the impact of stories on learning.</li>
                    <li>Teachers who want to incorporate the importance of students learning various types of texts such as science fiction, fantastic animal stories, fantasy, historical and realistic fiction, myths, legends, personal narratives, informational and procedural texts into their curricula.</li>
                </ul>
            </p>
        </div>


    </div>

</template>

<script>

export default {
    name : 'help',
    data () {
        return {
            language: navigator.language
        }
    },
    mounted () {
        if (this.language.includes('es')) {
            document.title = 'Ayuda'
        } else {
            document.title = 'Help'
        }
    },
    methods: {
        buscar () {
            // Obtener la entrada del usuario
            let entradaDeUsuario = document.getElementById('busqueda').value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

            // Obtener las preguntas del template
            let preguntas = document.getElementsByClassName('pregunta')

            for (let i = 0; i < preguntas.length; i++) {
                let titulo = preguntas[i].getElementsByTagName('h3')[0].textContent.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                // Esconder todas las preguntas
                preguntas[i].style.display = 'none'

                if (titulo.includes(entradaDeUsuario)){
                    // Si la entrada del usuario coincide con alguna palabra del titulo, mostrar la pregunta
                    preguntas[i].style.display = 'block'
                }
            }
        }
    }
}

</script>

<style src="@/assets/css/footer/help.css" scoped></style>
