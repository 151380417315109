<template>
  <!-- Portada -->
  <div v-if="TogglePortada" class="portada">
    <h3 v-if="language.includes('es')" @click="cerrarPortada">Continuar &#10143;</h3>
    <h3 v-else @click="cerrarPortada">Continue &#10143;</h3>
  </div>
  <!-- Fin Portada -->

  <div v-else class="container" id="container">
    <!--<a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a> -->
    <h1 v-if="language.includes('es')">Lecciones</h1>
    <h1 v-else>Lessons</h1>

    <!-- Lessons -->
    <div class="lessonsContainer">
    
      <div class="lesson">
        <router-link :to="{ name: 'lesson', params: { number: 'lesson-1', slide: 0 } }">
          <h3>Lección 1 - Las partes de la planta</h3> 
        </router-link>
      </div>
    
      <div class="lesson">
        <div class="locked" @click="mostrarPopup">
          <h3>
            Lección 2 - Estados del agua y su ciclo
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
            </svg>
          </h3> 
        </div>
      </div>

      <div class="lesson">
        <div class="locked" @click="mostrarPopup">
          <h3>
            Lección 3 - El Sistema Respiratorio
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
            </svg>
          </h3> 
        </div>
      </div>

      <div class="lesson">
        <div class="locked" @click="mostrarPopup">
          <h3>
            Lección 4 - El Sistema Circulatorio
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
            </svg>
          </h3> 
        </div>
      </div>

    </div>
    
    <Popup v-if="TogglePopup" v-on:metodo="cerrarPopup" :texto="message" />

  </div>

</template>

<script>
import Popup from '@/components/Popup.vue'

export default {
  components: {
    Popup
  },
  methods: {
    cerrarPortada () {
      this.TogglePortada = false // Cerrar la portada
    },

    // Funciones para controlar la musica de fondo
    playAudio () {
      try {
        const url = 'https://firebasestorage.googleapis.com/v0/b/viva-la-ciencia.appspot.com/o/Robots%20and%20Aliens%20-%20Joel%20Cummins.mp3?alt=media&token=b2983812-99eb-4be3-a36a-4642a4338f82'
        this.audio = new Audio(url)
        this.audio.loop = true
        this.audio.currentTime = 0
        this.audio.volume = 0.05
        this.audio.play()
      } catch (e) {
        console.log(e)
      }
    },
    pauseAudio () {
      if (this.audio) {
        this.audio.pause()
      }
    },

    // Funcion para mostrar el popup de leccion bloqueada
    mostrarPopup () {
      if (this.language.includes('es')) {
        this.message = 'No disponible en esta versión, <a href="mailto:support@funifelt.com?subject=Solicitud%20de%20acceso%20total%20a%20viva%20la%20ciencia" target="_blank">contáctanos</a\> para obtener más información'
      } else {
        this.message = 'Not available in this version, <a href="mailto:support@funifelt.com?subject=Request%20full%20access%20to%i%20love%science" target="_blank">contact us</a\> for more information'
      }      
      this.TogglePopup = true
    },
    cerrarPopup () {
      this.TogglePopup = false
    }
  },
  data () {
    return {
      mostrarInicial: false,
      TogglePortada: true, // Mostrar portada al cargar la pagina
      language: navigator.language,
      showButton: false,

      TogglePopup: false,
      message: '',
    }
  },
  mounted () {
    // Cambiar titulo de la ventana
    if (navigator.language.includes('es')) {
      document.title = 'Lecciones'  
    } else {
      document.title = 'Lessons'
    }
    
    // Iniciar musica de fondo
    this.playAudio()
  },
  unmounted () {
    // Detener musica de fondo
    this.pauseAudio()
  }
}
</script>

<style src="@/assets/css/content.css" scoped></style>
