<template>
    <div v-if="language.includes('es')">
        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1> Asesores </h1>  
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2FBrigitte.jpg?alt=media&token=ecfb206c-a5ee-40c3-8e63-b9ac8ff88f2b">
            </div>
            <div class="advisor-name">
                <h2>Jaddy Brigitte Nielsen Nino, Ph.D</h2>
                <h3>COORDINADORA</h3>
                <p>Confiada y fiable en sus habilidades para liderar equipos e inspirar a otros, con habilidades destacadas en dirección y pensamiento crítico. Competente en tecnología, codificación, idiomas y enseñanza. Habla español, inglés y neerlandés.</p>
            </div>
        </div>
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2FJuan.jpg?alt=media&token=67dc0231-e82f-4cf0-b5a8-1f57caa6a681">
            </div>
            <div class="advisor-name">
                <h2>Juan Camilo Ávila Laverde</h2>
                <h3>DESARROLLADOR</h3>
                <p>Dedicado y fuerte solucionador de problemas, con habilidades destacadas en la gestión del tiempo de desarrollo. Competente en codificación, programación y frameworks, estudia Ingeniería de Software. Habla español e inglés.</p>
            </div>
        </div>
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="">
            </div>
            <div class="advisor-name">
                <h2>Sergio Waldemar Garzón</h2>
                <h3>DESARROLLADOR DE VIDEOJUEGOS</h3>
                <p>Es un diseñador visual y programador que utiliza sofisticados programas de software digital para crear efectos visuales (VFX) realistas. Y anticipa cómo un usuario navegará e interactuará con sus creaciones para que jugadores y espectadores tengan la mejor experiencia posible. Habla español.</p>
            </div>
        </div>
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="">
            </div>
            <div class="advisor-name">
                <h2>Mariana Acuña</h2>
                <h3>DISEÑADORA GRÁFICA</h3>
                <p>Es capaz de adaptarse a los cambios. Sabe resolver problemas. Le gustan los cambios y está dispuesta a que los demás se sumen a ellos. Habla español.</p>
            </div>
        </div>
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="">
            </div>
            <div class="advisor-name">
                <h2>Yuly Andrea Torres Leguizamon</h2>
                <h3>DISEÑADORA GRÁFICA</h3>
                <p>Es muy optimista y ofrece ideas nuevas y frescas y nuevas perspectivas. Es capaz de mantener al equipo centrado con una mente positiva porque puede dar energía a los que la rodean y ayudará a todo el equipo a cruzar la línea de meta. Habla español e inglés.</p>
            </div>
        </div>
    </div>
    <div v-else>
        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1> Advisors </h1>  
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2FBrigitte.jpg?alt=media&token=ecfb206c-a5ee-40c3-8e63-b9ac8ff88f2b">
            </div>
            <div class="advisor-name">
                <h2>Jaddy Brigitte Nielsen Nino, Ph.D</h2>
                <h3>COORDINATOR</h3>
                <p>Reliable and confidence in her abilities to lead teams and inspire others, with distinguished directing and critical thinking skills. Competent at technology, coding, languages and teaching. She speaks Spanish, English and Dutch.</p>
            </div>
        </div>
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2FJuan.jpg?alt=media&token=67dc0231-e82f-4cf0-b5a8-1f57caa6a681">
            </div>
            <div class="advisor-name">
                <h2>Juan Camilo Ávila Laverde</h2>
                <h3>DEVELOPER</h3>
                <p>Dedicated and strong problem solver, with distinguished developing time-managenent skills. Competent at coding, programming and frameworks, studies Software Engineer. He speaks Spanish and English.</p>
            </div>
        </div>
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="">
            </div>
            <div class="advisor-name">
                <h2>Sergio Waldemar Garzón</h2>
                <h3>GAME DEVELOPER</h3>
                <p>He is a visual designer and programmer who uses sophisticated digital software programs to create realistic visual effects (VFX). And he anticipates how a user will navigate and interact with their creations so that players and viewers can have the best experience possible. He speaks Spanish.</p>
            </div>
        </div>
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="">
            </div>
            <div class="advisor-name">
                <h2>Mariana Acuña</h2>
                <h3>GRAPHIC DESIGNER</h3>
                <p>She is able to adapt to change. She is someone who is a problem solver. She is welcome change and is keen to get others on board too. She speaks Spanish.</p>
            </div>
        </div>
        <div class="advisor-container">
            <div class="advisor-photo">
                <img src="">
            </div>
            <div class="advisor-name">
                <h2>Yuly Andrea Torres Leguizamon</h2>
                <h3>GRAPHIC DESIGNER</h3>
                <p>She is very optimistic who offers new and fresh ideas and new perspectives. She can keep the team focused with a positive mind because she can energize those around her and will help the whole team cross the finish line. She speaks Spanish and English.</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name : 'advisors',
    data () {
        return {
            language: navigator.language
        }
    },
    mounted () {
        if (this.language.includes('es')) {
            document.title = 'Asesores'
        } else {
            document.title = 'Advisors'
        }
    }
}

</script>

<style src="@/assets/css/footer/advisors.css" scoped></style>
