import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'
import LoginView from '@/views/Login.vue'
import RegistroView from '@/views/Registro.vue'
import PerfilView from '@/views/Perfil.vue'
import contentView from '@/views/content.vue'
import LessonsView from '@/views/Lessons.vue'
import HomeView from '@/views/Home.vue'

// Footer
import AdvisorsView from '@/views/footer/Advisors.vue'
import HelpView from '@/views/footer/Help.vue'
import ContactView from '@/views/footer/Contact.vue'
import ResearchView from '@/views/footer/Research.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/content'
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ingresar',
    name: 'ingresar',
    component: LoginView
  },
  {
    path: '/registrarse',
    name: 'registrarse',
    component: RegistroView
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: PerfilView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/content',
    name: 'content',
    component: contentView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lessons/:number/:slide',
    name: 'lesson',
    component: LessonsView,
    props: true,
    meta: {
     requiresAuth: true
    }
  },
  // Enlaces del footer
  {
    path: '/advisors',
    name: 'advisors',
    component: AdvisorsView
  }, 
  {
    path: '/help',
    name: 'help',
    component: HelpView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/research',
    name: 'research',
    component: ResearchView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (getAuth().currentUser) {
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
