<template>
    <div v-if="language.includes('es')">
        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1>Investigación</h1>

        <div class="tema">
            <p>
                Comenzamos un estudio que revela las diversas formas en que el uso de los Objetos Virtuales de Aprendizaje (OVAs) puede entenderse como mediador pedagógico diseñado intencionalmente para fomentar el aprendizaje.
            </p>
        </div>

        <h3>¿Cómo trabajamos con los responsables de las políticas educativas?</h3>
        <div class="tema">
            <p>
                Los informes describen cómo los recursos educativos en Colombia y América Latina deben ser flexibles y adaptables para permitir su efectividad en una variedad de entornos educativos diferentes, desde la instrucción en toda la clase hasta el aprendizaje individualizado.
            </p>
            <ul>
                <li>
                    <a href="https://siteal.iiep.unesco.org/sites/default/files/sit_accion_files/siteal_colombia_0404.pdf" target="_blank">
                        <h4>UNESCO y SITEAL, 2016-2026</h4>
                    </a>
                    <p>
                        Se propone avanzar hacia un sistema educativo de calidad que promueva el desarrollo económico y social del país, y la construcción de una sociedad con justicia, equidad, respeto y reconocimiento de las diferencias.
                    </p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/onlineshop" target="_blank"> 
                        <h4>Reflexiones investigativas sobre la educación en Iberoamérica, 2019</h4>
                    </a>
                </li>
            </ul>
        </div>

        <h3>¿Cómo apoyan nuestros juegos el aprendizaje?</h3>
        <div class="tema">
            <p>
                Nuestras investigaciones se centran en la efectividad del aprendizaje basado en juegos y la gamificación, tanto en general como en la plataforma FUNIFELT para juegos educativos.
            </p>
            <ul>
                <li>
                    <a href="http://www.scielo.org.co/pdf/ridi/v11n3/2389-9417-ridi-11-03-569.pdf" target="_blank"> 
                        <h4>Gamificación y fortalecimiento de habilidades orales en inglés para la infancia, 2021</h4>
                    </a>
                    <p>
                        El objetivo de la investigación fue identificar el impacto de la implementación de un material educativo gamificado para fortalecer la habilidad oral en inglés en estudiantes de la infancia.
                    </p>
                </li>
                <li>
                    <a href="https://repository.uniminuto.edu/jspui/handle/10656/7626?mode=full" target="_blank"> 
                        <h4>Gamificación para activar el proceso de aprendizaje de los niños, 2018</h4>
                    </a>
                    <p>
                        Se llevaron a cabo actividades semanales con gamificación que fomentaron la socialización y la integración de los niños, la experimentación y una mayor concentración en los contenidos. El proceso desarrollado a través del uso de juegos llevó a evidenciar un cambio en las deficiencias encontradas al inicio del proceso.
                    </p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/onlineshop" target="_blank">
                        <h4>Desarrollo motor en los infantes, 2022</h4>
                    </a>
                    <p>
                        Escrito por cuatro graduados en ciencias del deporte, formó parte de una propuesta de investigación que se desarrolló durante un período de 2 años. Se construyó a partir de actividades desarrolladas de forma remota y mixta con niños de entre 1 y 8 años.
                    </p>
                </li>
            </ul>
        </div>

        <h3>¿Cómo funciona FUNIFELT en el plurilingüismo?</h3>
        <div class="tema">
            <ul>
                <li>
                    <a href="https://www.funifelt.com/onlineshop" target="_blank">
                        <h4>El Reino Unido: Narrativas de los universitarios Colombianos. Roles, lenguaje y contexto</h4>
                    </a>
                </li>
                <li>
                    <a href="https://www.funifelt.com/onlineshop" target="_blank">
                        <h4>Innovative Ideas for Cosmopolitan Educators</h4>
                    </a>
                </li>
            </ul>
        </div>

        <h3>Premios y honores de FUNIFELT</h3>
        <div class="tema">
            <ul>
                <li>
                    <h4>Fundación Relefónica de Movistar (España) - 2017</h4>
                    <p>
                        Reconocimiento a FUNIFELT por su innovador proyecto de educación bilingüe.
                    </p>
                </li>
                <li>
                    <h4>Consejo Latinoamericano de Ciencias Sociales (CLACSO) - 2018</h4>
                    <p>
                        Invitación a los miembros de FUNIFELT a participar en la VII Escuela Internacional de Posgrado de la Red INJU en Tegucigalpa, Honduras.
                    </p>
                </li>
                <li>
                    <h4>Cámara de Comercio de Bogotá - Colombia - 2019</h4>
                    <p>
                        Reconocimiento a FUNIFELT por lograr sus sueños a través de buenas prácticas educativas que permiten apoyar a diversas poblaciones.
                    </p>
                </li>
                <li>
                    <h4>Coloquio CIFLEX organizado por la Universidad de San Buenaventura - 2020</h4>
                    <p>
                        A pesar de ser un año de cambios y reestructuración debido a la COVID-19 que afectó a gran parte de la población, FUNIFELT participó en el IV Coloquio Internacional CIFLEX organizado por la Universidad de San Buenaventura, Cartagena - Colombia, para discutir la importancia de promover la internacionalización del currículo.
                    </p>
                </li>
                <li>
                    <h4>RYBAKOV - 2021</h4>
                    <p>
                        FUNIFELT recibió un premio en Rusia por desarrollar una educación centrada en los infantes, generando un excelente ambiente educativo bilingüe. Este premio ha sido descrito por la revista Forbes como "El Premio Novato para Filántropos en Educación".
                    </p>
                </li>
                <li>
                    <h4>TESOL - 2021</h4>
                    <p>
                        FUNIFELT participa en TESOL Colombia como ponente, explicando la era del bilingüismo a través de herramientas tecnológicas. Este ha sido uno de los eventos más importantes sobre la enseñanza del inglés como lengua extranjera en América Latina y para los profesores de inglés en América del Norte.
                    </p>
                </li>
            </ul>
        </div>

        <h3>Proyectos y Logros de FUNIFELT</h3>
        <div class="tema">
            <ul>
                <li>
                    <a href="https://www.funifelt.com/languageapps" target="_blank">
                        <h4>Aplicaciones</h4>
                    </a>
                    <p>
                        FUNIFELT ha desarrollado cuatro aplicaciones diferentes con el objetivo de mejorar la competencia comunicativa en inglés, español, italiano y neerlandés. Se enfocan en estudiantes autónomos, quienes aprenderán nuevo vocabulario y podrán escuchar cómo se pronuncia. Estas aplicaciones cuentan con más de 78 niveles, juegos al final de cada nivel y te permiten disfrutar y aprender al mismo tiempo (haz clic en la imagen para obtener más información).
                    </p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/schools" target="_blank">
                        <h4>Escuelas</h4>
                    </a>
                    <p>
                        Tenemos programas escolares que ayudan a los estudiantes basados en programas curriculares nacionales e internacionales, generando un ambiente cálido de respeto y tolerancia hacia otras culturas, centrados en el plurilingüismo. ¡Forma parte de nuestras escuelas!
                    </p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/spanish-4-foreigners" target="_blank">
                        <h4>Español para Extranjeros</h4>
                    </a>
                    <p>
                        Ofrecemos clases particulares desde principiantes hasta avanzados a precios accesibles y apoyamos el proceso educativo y turístico de nuestros estudiantes que desean estudiar y viajar a Latinoamérica o España. Nuestros estudiantes tienen diversos materiales didácticos y académicos para los exámenes DELE y SIELE.
                        <br>
                        Nuestros estudiantes pueden ser voluntarios en Latinoamérica, participando en nuestros programas educativos en línea y presenciales.
                    </p>
                </li>
                <li>
                    <h4>Donación</h4>
                    <p>
                        Niños de Estados Unidos ayudan a nuestra Fundación.
                        <br>
                        Nuestra Fundación recibió libros donados por niños de Estados Unidos. Estos libros son historias infantiles como "My Little Pony", "Frozen", entre otros.
                        <br>
                        Este tipo de donación ayuda a nuestros niños a disfrutar de la lectura y la escritura, ya que tienen la oportunidad de leer historias que forman parte de programas de televisión para niños. ¡Únete a nuestro programa!
                    </p>
                </li>
                <li>
                    <a href="https://www.mineducacion.gov.co/portal/salaprensa/Noticias/363197:Colombia-ya-tiene-su-Plan-Nacional-Decenal-de-Educacion" target="_blank">
                        <h4>PNDE - 2016-2026</h4>
                    </a>
                    <p>
                        FUNIFELT y el Ministerio de Educación Nacional de Colombia presentan el Plan Decenal de Educación. Formamos parte del Comité de Gestión y participamos con el Ministerio de Educación Nacional en la presentación del Plan Decenal de Educación 2016-2026 para todos los colombianos. Nos sentimos comprometidos con promover "el camino hacia la calidad y la equidad".
                    </p>
                </li>
                <li>
                    <h4>Grupo de Investigación</h4>
                    <p>
                        FUNIFELT cuenta con un grupo que se creó en 2011 para apoyar el proceso de investigación de algunos grupos de aprendices. Este grupo forma parte de la Fundación Internacional FUNIFELT y se enfoca en tres estados principales: enseñanza, investigación y extensión. Al mismo tiempo, hay tres líneas de investigación:

                        Creación de subgrupos de investigación que ayudan a los investigadores en el proceso de investigación.
                        Desarrollo de proyectos de investigación interdisciplinarios en el campo de la pedagogía, la enseñanza y la investigación, proporcionando herramientas efectivas para el dominio a través de estrategias sistemáticas, aplicación y enseñanza secuencial.
                        Elaboración de una propuesta de investigación para promover la educación bilingüe y plurilingüe.
                    </p>
                </li>
                <li>
                    <h4>Difusión de Artículos de Investigación</h4>
                    <p>
                        <a href="https://revistaedu.co/etiqueta/jaddy-brigitte-nielsen-nino/" target="_blank">
                            <strong><em>Horario adecuado para los académicos</em></strong>
                        </a>
                        <br>
                        Este artículo trata sobre el horario adecuado para los niños que están en el jardín de infantes. Esta investigación fue realizada para nuestra Fundación y Gimnasio Bilingüe ubicado en Colombia. Este documento está escrito en español de acuerdo con el contexto del estudio.
                        <br>
                        <a href="https://revistainnovamos.com/2018/06/12/los-horarios-de-clase-una-tortura-que-debe-ser-suprimida-en-el-sistema-educativo/" target="_blank">
                            <strong><em>Evitando el horario tradicional para nuestros niños</em></strong>
                        </a>
                        <br>
                        Este es el artículo más reciente sobre las dimensiones de enseñanza y aprendizaje que los niños deben tener. Desafortunadamente, los programas de jardín de infantes utilizan un horario para enseñar, pero no tienen en cuenta el proceso de aprendizaje de cada estudiante. Cada niño necesita diferentes estrategias y aprenden en diferentes etapas sin importar la edad o el grado en el que estén involucrados.
                    </p>
                </li>
                <li>
                    <h4>Editorial</h4>
                    <p>
                        La editorial de FUNIFELT ha publicado procesos académicos y científicos en varios formatos, pero también ayuda a publicar a nuestros miembros y personas interesadas para mostrar a la sociedad la innovación académica centrada en la educación. ¡Contáctanos!
                    </p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/podcasts" target="_blank">
                        <h4>Podcasts</h4>
                    </a>
                    <p>
                        FUNIFELT tiene una amplia colección de podcasts en inglés y español sobre diversos temas que pueden ser de interés, creados con profesionales.
                    </p>
                </li>
            </ul>
        </div>
    </div>

    <!-- English version -->
    <div v-else>
        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1>Research</h1>

        <div class="tema">
            <p>
                We started a study that reveal the various ways using Virtual Learning Objects (VLO) can be understood as pedagogical mediator intentionally designed to foster learning.
            </p>
            <p>
                XXXXX document by Teacher UMB.
            </p>
        </div>

        <h3> How do we work with policymakers? </h3>
        <div class="tema">
            <p> 
                Reports describe how resources in education in Colombia and Latin-American should be flexible and adaptable to allow for its effectiveness in a range of different educational settings, from whole-class instruction to individualized learning.
            </p>
            <ul>
                <li>
                    <a href="https://siteal.iiep.unesco.org/sites/default/files/sit_accion_files/siteal_colombia_0404.pdf" target="_blank">
                        <h4>UNESCO and SITEAL, 2016-2026</h4>
                    </a>
                    <p>It is proposed to move towards a quality education system that promotes development economic and social development of the country, and the construction of a society with justice, equity, respect and recognition of differences.</p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/onlineshop" target="_blank"> 
                        <h4>Reflexiones investigativas sobre la educacion en iberoamerica, 2019</h4>
                    </a>
                </li>
            </ul>
        </div>

        <h3>How do our games support learning?</h3>
        <div class="tema">
            <p>
                Our researches focus on the effectiveness of game-based learning and gamification, both in general and as implemented on FUNIFELT platform for educational games.
            </p>
            <ul>
                <li>
                    <a href="http://www.scielo.org.co/pdf/ridi/v11n3/2389-9417-ridi-11-03-569.pdf" target="_blank"> 
                        <h4>Gamification and strengthening oral skills in English for childhood, 2021</h4>
                    </a>
                    <p>
                        The research was aimed to identify the impact of implementing a gamified educational material to strengthen oral ability in English in childhood students.
                    </p>
                </li>
                <li>
                    <a href="https://repository.uniminuto.edu/jspui/handle/10656/7626?mode=full" target="_blank"> 
                        <h4>Gamification to active children’s learning process, 2018</h4>
                    </a>
                    <p>
                        Weekly activities were carried out with gamification that fostered the socialization and integration of children, experimentation and a greater concentration on the contents. The process developed through the use of games led to evidence of a change in the shortcomings found at the beginning of the process.
                    </p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/onlineshop" target="_blank"> 
                        <h4>Desarrollo motor en los infantes, 2022</h4>
                    </a>
                    <p>
                        It was written by four graduates in sports science, and it was part of a research proposal that was developed over a period of 2 years, it is built from activities developed remotely and blended with children between 1 and 8 years old.
                    </p>
                </li>
            </ul>
        </div>

        <h3>How does FUNIFELT work in Plurilinguism?</h3>
        <div class="tema">
            <ul>
                <li>
                    <a href="https://www.funifelt.com/onlineshop" target="_blank">
                        <h4>El Reino Unido: Narrativas de los universitarios Colombianos. Roles, lenguaje y contexto</h4>
                    </a>
                </li>
                <li>
                    <a href="https://www.funifelt.com/onlineshop" target="_blank">
                        <h4>Innovative Ideas for Cosmopolitan Educators</h4>
                    </a>
                </li>
            </ul>
        </div>

        <h3>FUNIFELT Awards and Honours</h3>
        <div class="tema">
            <ul>
                <li>
                    <h4>Foundation Relefónica of Movistar (Spain) - 2017</h4>
                    <p>
                        Recognized FUNIFELT for its innovative project on bilingual education.
                    </p>
                </li>
                <li>
                    <h4>Latin American Council of Social Sciences (CLACSO) - 2018</h4>
                    <p>
                        Invited FUNIFELT members to participate in the VII International Graduate School of the INJU Network in Tegucigalpa - Honduras.
                    </p>
                </li>
                <li>
                    <h4>Chamber of Commerce of Bogotá - Colombia - 2019</h4>
                    <p>
                        Recognized FUNIFELT for achieving its dreams through its good educational practices allowed to support diverse populations.
                    </p>
                </li>
                <li>
                    <h4>Colloquium CIFLEX organized by the University of San Buenaventura - 2020</h4>
                    <p>
                        Although it was a year of changes and reestructuring due to COVID-19 that hit a large part of the population, FUNIFELT participated in the IV CIFLEX internation colloquium organized by the University of San Buenaventura, Catagena - Colombia to discuss the importance of promoting the internalization of the curriculum.
                    </p>
                </li>
                <li>
                    <h4>RYBAKOV - 2021</h4>
                    <p>
                        FUNIFELT received an award in Russia because they developed an education focused on infants, generating an excellent bilingual educational environment. This award has been described by FORBES magazine as "The Novice Award for Philanthropists in Education".
                    </p>
                </li>
                <li>
                    <h4>TESOL - 2021</h4>
                    <p>
                        FUNIFELT is a participating in TESOL Colombia as a rapporteur, explaining the era of bilingualism through technological toolds, this has been one of the most important events about ELT in Latin America and for English teaches in North America.
                    </p>
                </li>
            </ul>
        </div>

        <h3>Projects and Achievements</h3>
        <div class="tema">
            <ul>
                <li>
                    <a href="https://www.funifelt.com/languageapps" target="_blank">
                        <h4>Apps</h4>
                    </a>
                    <p>
                        FUNIFELT has developed four different applications in order to enhance communicative competence in English, Spanish, Italian and Dutch. They focus on autonomous learners. They will learn new vocabulary and will be able to hear how it is pronounced, those apps have more than 78 levels, games at the end of each level and more you enjoy and learn at the same time (click on the image for more information).
                    </p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/schools" target="_blank">
                        <h4>Schools</h4>
                    </a>
                    <p>
                        We have school programs that help students based on national and international curricular programs, generating a warm environment of respect and tolerance with other cultures focus on plurilingualism.  Be part of our schools!
                    </p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/spanish-4-foreigners" target="_blank">
                        <h4>Spanish 4 foreigners</h4>
                    </a>
                    <p>
                        We offer one on one lessons from Beginners to Advance at suitable prices and we support the educative and tourist process for our students who want to study and travel to Latinoamerica or Spain.  Our students have several didactics and academic materials for DELE and SIELE tests.
                        <br>
                        Our students can be a volunteer in Latino America who participated in our educative programs online and onsite.
                    </p>
                </li>
                <li>
                    <h4>Donation</h4>
                    <p>
                        Children from the USA help our Foundation<br>
                        Our Foundation received some books donated by children from the United States of America.  Those books are about children's stories such as My little pony, Frozen, and so on.
                        <br>
                        This kind of donation helps our kids to enjoy reading and writing because they can have the opportunity to read stories that are part of TV programs for kids.  Be part of our program!
                    </p>
                </li>
                <li>
                    <a href="https://www.mineducacion.gov.co/portal/salaprensa/Noticias/363197:Colombia-ya-tiene-su-Plan-Nacional-Decenal-de-Educacion" target="_blank">
                        <h4>PNDE - 2016-2026</h4>
                    </a>
                    <p>
                        FUNIFELT & Ministry of National Education in Colombia present the Decennial Education Plan. We are part of the Management Committee and we participated with the Ministry of National Education in the presentation of the Decennial Education Plan 2016-2026 for all Colombians. We feel committed to promoting "the path toward quality and equity"
                    </p>
                </li>
                <li>
                    <h4>Research Group</h4>
                    <p>
                        FUNIFELT has a group that was created in 2011 and to support the research process of some apprentice groups. This group is part of the International Foundation FUNIFELT focuses on three main states: teaching, research and extension but at the same time, there are three lines of inquiry:
                        <br>
                        1. Creating research subgroups that help researchers in the lifting of the inquiry process.
                        <br>
                        2. Developing a-cross disciplinary research projects on the pedagogy field, teaching, and research, training effective tools to master through the systematic strategies, application and sequential teaching.  
                        <br>
                        3. Starting a research proposal to promote bilingualism and plurilingualism education. 
                    </p>
                </li>
                <li>
                    <h4>Divulgation Research papers</h4>
                    <p>
                        <a href="https://revistaedu.co/etiqueta/jaddy-brigitte-nielsen-nino/" target="_blank">
                            <strong><em>The suitable schedule for scholars</em></strong>
                        </a>
                        <br>
                        This article about the appropriate schedule for kids who are in kindergarten. This research was made for our Foundation and Gimnasio Bilingüe located in Colombia.  This paper is writing in Spanish according to the context that was the study.    
                        <br>
                        <a href="https://revistainnovamos.com/2018/06/12/los-horarios-de-clase-una-tortura-que-debe-ser-suprimida-en-el-sistema-educativo/" target="_blank">
                            <strong><em>Avoiding traditional schedule for our kids</em></strong>
                        </a>
                        <br>
                        This is the most recent article about the teaching and learning dimensions that kids should have. Unfortunately,  kindergarten programs use the schedule to teach something but they don't take into account each student's learning process,  each kid needs different strategies and they learn in different steps doesn't matter the age or grade in which they are involved.
                    </p>
                </li>
                <li>
                    <h4>Publishing house</h4>
                    <p>
                        FUNIFELT publishing house has published academic and scientific process in several formats,  but also it helps to publish to our members and people who are interested to let show to society the academic innovation focus on education. Contact us!
                    </p>
                </li>
                <li>
                    <a href="https://www.funifelt.com/podcasts" target="_blank">
                        <h4>Podcasts</h4>
                    </a>
                    <p>
                        FUNIFELT has for you a wide collection of podcasts in English and Spanish on various topics that may be of interest to you made with professionals.
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            language: navigator.language
        }
    },
    mounted () {
        if (this.language.includes('es')) {
            document.title = 'Investigación'
        } else {
            document.title = 'Research'
        }
    }
}
</script>

<style src="@/assets/css/footer/research.css" scoped></style>
