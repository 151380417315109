<template>
    <div v-if="language.includes('es')" class="container" id="container">
        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1> Perfil </h1>
        <h3> {{ email }} </h3>
        <h4> {{ userData.nombre }} </h4>
        <h4> Miembre desde: {{ userData.fecha_creacion }} </h4>
        <h4 v-if="pais"> País: {{ pais }} </h4>
        <button v-if="!mostrarFormulario" @click="mostrarFormulario=true" class="logout_button">Cambiar contraseña</button><br>
        <div v-if="mostrarFormulario" id="password_form">
            <input id="newPassword" type="password" placeholder="Contraseña Nueva">
            <input id="confirmPassword" type="password" placeholder="Confirmar Contraseña Nueva">
            <button @click="changePassword()" class="logout_button"> Cambiar Contraseña </button>
        </div>
        <button @click="cerrarSesion()" class="logout_button"> Cerrar Sesión</button>
    </div>
    <div v-else class="container" id="container">
        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1> Profile </h1>
        <h3> {{ email }} </h3>
        <h4> {{ userData.nombre }} </h4>
        <h4> Member since: {{ userData.fecha_creacion }} </h4>
        <h4 v-if="pais"> Country: {{ pais }} </h4>
        <button v-if="!mostrarFormulario" @click="mostrarFormulario=true" class="logout_button">Change Password</button><br>
        <div v-if="mostrarFormulario" id="password_form">
            <input id="newPassword" type="password" placeholder="New Password">
            <input id="confirmPassword" type="password" placeholder="Confirm New Password">
            <button @click="changePassword()" class="logout_button"> Change Password </button>
        </div>
        <button @click="cerrarSesion()" class="logout_button"> Logout </button>
    </div>

    <Popup v-if="TogglePopup" v-on:metodo="cerrarOpcion" :texto="Message"/>

</template>

<script>
import { getAuth, updatePassword, signOut } from 'firebase/auth'
import router from '@/router/index.js'
import Popup from '@/components/Popup.vue'
import { db } from '@/main.js'
import { collection, query, where, getDocs } from 'firebase/firestore'

export default {
    name: 'Perfil',
    components: {
        Popup
    },
    data () {
        let auth = getAuth()
        const emails = auth.currentUser.email
        return {
            email: emails,
            TogglePopup: false,
            Message: '',
            language: navigator.language,
            userData: {},
            pais: false,
            mostrarFormulario: false
        }
    },
    async mounted () {
        // Cambiar titulo de la ventana
        if (this.language.includes('es')) {
            document.title = 'Perfil'
        } else {
            document.title = 'Profile'
        }

        // Consultar la informacion del usuario en la base de datos
        let queryUserData
        const userID = getAuth().currentUser.uid
        const coleccionDeUsuarios = collection(db, 'Usuarios') // Acceder a la coleccion (Usuarios) en la base de datos
        const qUsers = query(coleccionDeUsuarios, where('UID', '==', userID)) // Hacer la consulta por el correo del usuario
        const querySnapshotUsers = await getDocs(qUsers)
        querySnapshotUsers.forEach((doc) => {
            queryUserData = doc.data()
        })

        // Actualizar las variables para mostrar en el template
        this.userData = queryUserData
        try {
            this.pais = this.userData.ubicacion.country
        } catch (e) {
            console.log('No se pudo obtener el pais')
        }

    },
    methods: {
        // Funcion para cambiar contraseña
        changePassword () {
            const newPassword = document.getElementById('newPassword').value // Obtener la nueva contraseña
            const confirmPassword = document.getElementById('confirmPassword').value // Obtener la confirmacion de la nueva contraseña
         
            // Verificar que las contraseñas coincidan
            if (newPassword != confirmPassword) {
                this.Message = 'Las contraseñas no coinciden'
                this.TogglePopup = true // Mostrar popup
                return
            }

            // Verificar que la contraseña nueva cumple con los requisitos de seguridad
            if (!this.contraseñaValida(newPassword)) {
                this.Message = 'La contraseña debe tener al menos: <br>6 Caracteres <br>1 Mayúscula, <br>1 Minúscula <br>1 Número <br>1 Cáracter Especial (!@#$%^&*)'
                this.TogglePopup = true // Mostrar popup
                return
            }

            // Verificar que el usuario desea cambiar la contraseña
            if (!confirm("Seguro que deseas cambiar la contraseña?")) {
                console.log('Se cancelo el cambio de contraseña')
                return
            }

            const auth = getAuth()
            const user = auth.currentUser

            // Actualizar la contraseña en el servicion de autenticacion de Firebase
            updatePassword(user, newPassword).then(() => {
                console.log('Contraseña actualizada correctamente')
                document.getElementById('newPassword').value = ''
                document.getElementById('confirmPassword').value = ''
            }).catch((error) => {
                console.log(error.message)
            })
        },
        // Funcion para cerrar sesion
        cerrarSesion () {      
            const auth = getAuth()
            signOut(auth).then(() => {
                router.push('/ingresar')
            })
        },
        // Funcion para validar que la contraseña cumpla con los requisitos de seguridad
        contraseñaValida (contraseña) {
            /* 
                La contraseña debe tener al menos:
                - 6 caracteres
                - 1 mayuscula
                - 1 minuscula
                - 1 numero
                - 1 caracter especial (!@#$%^&*)
            */
            const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/
            return regex.test(contraseña)
        },
        // Funcion para cerrar el popup
        cerrarOpcion () {
            this.TogglePopup = false
        }
    }
}
</script>

<style src="@/assets/css/perfil.css" scoped></style>
