<template>
    <div v-if="language.includes('es')">
        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1> Contactanos </h1>  

        <div class="container">
            <div class="area">
                <img class="iconos" src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-telefono.png?alt=media&token=75ab031c-df5b-42e8-b085-bffca8923c9d">
                <h2>Llamanos</h2>
                <h3>Fax:</h3>
                <ul>
                    <li>1-800-000-0000</li>
                </ul>

                <h3>Teléfono:</h3>
                <ul>
                    <li>+57 3053493168 (COL)</li>
                    <li>+31 6 87623593 (NL)</li>
                </ul>
            </div>

            <div class="area">
                <img class="iconos" src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-email.png?alt=media&token=f1c99ed7-ac99-4697-8d12-35dcb701d938">
                <h2>Escríbenos</h2>
                <ul>
                    <li>support@funifelt.com</li>
                    <li><a href="https://es.funifelt.com/contact" target="_blank">Formulario de contacto</a></li>
                </ul>
            </div>

            <div class="area">
                <img class="iconos" src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-mapa.png?alt=media&token=17a5f568-4df7-48e4-93ef-1c914031c9bf">
                <h2>Encuentranos</h2>
                
                <h3>Sudamérica:</h3>
                <ul>
                    <li>Bogotá, Colombia</li>
                    <li>Zipaquirá, Colombia</li>
                    <li>Ricaurte, Colombia</li>
                </ul>

                <h3>Europa:</h3>
                <ul>
                    <li> Amsterdam, Países Bajos </li>
                </ul>

            </div>  
        </div>

    </div>

    <div v-else>
        <a href="https://www.funifelt.com/" target="_blank"><img src="@/assets/images/Logo_Funifelt_Color.png" class="logo"></a>
        <h1> Contact Us </h1>  

        <div class="container">
            <div class="area">
                <img class="iconos" src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-telefono.png?alt=media&token=75ab031c-df5b-42e8-b085-bffca8923c9d">
                <h2>Call us</h2>
                <h3>Fax:</h3>
                <ul>
                    <li>1-800-000-0000</li>
                </ul>
                <h3>Phone:</h3>
                <ul>
                    <li>+57 3053493168 (COL)</li>
                    <li>+31 6 87623593 (NL)</li>
                </ul>
            </div>

            <div class="area">
                <img class="iconos" src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-email.png?alt=media&token=f1c99ed7-ac99-4697-8d12-35dcb701d938">
                <h2>Write to us at</h2>
                <ul>
                    <li>support@funifelt.com</li>
                    <li><a href="https://funifelt.com/contact" target="_blank">Contact form</a></li>
                </ul>
            </div>

            <div class="area">
                <img class="iconos" src="https://firebasestorage.googleapis.com/v0/b/relatos-mitos-y-leyendas.appspot.com/o/Fondos%2Ficono-mapa.png?alt=media&token=17a5f568-4df7-48e4-93ef-1c914031c9bf">
                <h2>Find us at</h2>
                <h3>South America:</h3>
                <ul>
                    <li>Bogotá, Colombia</li>
                    <li>Zipaquirá, Colombia</li>
                    <li>Ricaurte, Colombia</li>
                </ul>
                <h3>Europe:</h3>
                <ul>
                    <li>Amsterdam, The Netherlands</li>
                </ul>
            </div>  
        </div>
    </div>

</template>

<script>

export default {
    name: 'contact',
    data () {
        return {
            language: navigator.language
        }
    },
    mounted () {
        if (this.language.includes('es')) {
            document.title = 'Contactanos'
        } else {
            document.title = 'Contact Us'
        }
    }
}

</script>

<style src="@/assets/css/footer/contact.css" scoped></style>
