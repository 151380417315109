<template>
  <div class="container">
    <div class="slide">      
      <!-- Imagen principal -->
      <img v-if="!renderComponent" :src="mainImage" class="main-image">
      
      <!-- Componente de la actividad -->
      <component v-else v-bind:is="activity"></component>

      <!-- Boton para iniciar la actividad (componente) -->
      <div v-if="activity && !renderComponent">
        <button @click="renderComponent=true" class="activityButton"> &#9654; </button>
      </div>      

      <div v-if="activity && mostrarVotos" class="controlesVotos">
        <button @click="enviarOpinion('L')" class="botonesOpinion-L">👍</button>
        <button @click="enviarOpinion('D')" class="botonesOpinion-D">👎</button>
      </div>

    </div>
  </div>

</template>

<script>
import { db } from '@/main.js'
import { collection, query, where, getDocs, setDoc, doc, increment, arrayUnion } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { defineAsyncComponent } from 'vue'

export default {
  props: {
    slide: String, // Recibir la diapositiva a consultar
    number: String, // Recibir la leccion de la diapositiva a consultar (coleccion en firebase db)
  },
  components: {
    puzzle: defineAsyncComponent(() => import("@/components/activities/puzzle.vue")),
    paint: defineAsyncComponent(() => import("@/components/activities/paint.vue")),
    roulette: defineAsyncComponent(() => import("@/components/activities/roulette.vue")),
  },
  data: () => { // Crear las variables para enviar al template
    return {
      mainImage: null,

      // Para diapositivas con actividad (componente integrado)
      activity: null,
      renderComponent: null,
      mostrarVotos: null
    }
  },
  async mounted () {
    const lesson = this.$route.params.number
    const slide = this.$route.params.slide
  
    const coleccionDeLesson = collection(db, lesson) // Acceder a la coleccion (lesson) en la base de datos
    let queryFondoUrl
    const q = query(coleccionDeLesson, where('slide', '==', slide)) // Hacer la consulta de la diapositiva actual
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      queryFondoUrl = doc.data().background
      this.activity = doc.data().activity
    })
    this.mainImage = queryFondoUrl


    // Cambiar el titulo de la ventana
    document.title = 'Lesson 1 - ' + slide


    // Si la diapositiva tiene actividad, consultar si el usuario ya dio like o dislike
    if (this.activity) {
      let queryListaLikes
      let queryListaDislikes 

      // Consultar si el usuario ya dio like o dislike a esta actividad
      const coleccionDePuntajes = collection(db, 'PuntajesActividades') // Acceder a la coleccion (PuntajesActividades) en la base de datos
      const qPuntajes = query(coleccionDePuntajes, where('actividad', '==', this.activity)) // Hacer la consulta de las listas de likes y dislikes
      const querySnapshotPuntajes = await getDocs(qPuntajes)
      querySnapshotPuntajes.forEach((doc) => {
        queryListaLikes = doc.data().like
        queryListaDislikes = doc.data().dislike
      })

      // Verificar si el usuario ya dio like o dislike
      const userID = getAuth().currentUser.uid
      if (queryListaLikes.users.includes(userID)) {
        this.mostrarVotos = false
      } else if (queryListaDislikes.users.includes(userID)) {
        this.mostrarVotos = false
      } else {
        this.mostrarVotos = true
      }
    
      // Registrar la visualizacion de la actividad
      this.registrarVisualizacion()
    } 
    
  },
  methods: {
    // Enviar la opinion del usuario a la base de datos
    enviarOpinion (opinion) {
      // Guardar el voto en la base de datos
      const docRef = doc(db, 'PuntajesActividades', this.activity)
      if (opinion == 'L') {        
        setDoc(docRef, {
          like: {
            counter: increment(1),
            users: arrayUnion(getAuth().currentUser.uid)
          }
        }, { merge: true }) 
      } else {
        setDoc(docRef, {
          dislike: {
            counter: increment(1),
            users: arrayUnion(getAuth().currentUser.uid)
          }
        }, { merge: true }) 
      }

      // Ocultar los botones de like y dislike
      this.mostrarVotos = false
    },

    // Funcion para registrar la visualizacion de la jerga
    registrarVisualizacion() {
      const docRef = doc(db, 'PuntajesActividades', this.activity)
      setDoc(docRef, {
        visualizaciones: increment(1)
      }, { merge: true })
    }
  }
}
</script>

<style src="@/assets/css/slide.css" scoped></style>
