<template>
  <Suspense>
    <template #default>
      <Lesson v-if="renderComponent" :number="$route.params.number" :slide="$route.params.slide"/>
    </template>
    <template #fallback>
      <img src="@/assets/images/Logo_Funifelt_Color.png">
    </template>
  </Suspense>

  <!-- Botones para cambiar de diapositiva -->
  <div style="margin-bottom: -133px">
    <button @click="moverDiapositiva('<')" class="moveButton">&#9664;</button>
    <button @click="moverDiapositiva('>')" class="moveButton">&#9654;</button>
  </div>
</template>

<script>
import Lesson from '@/components/Lesson.vue'

// Firebase database imports
import { db } from '@/main.js'
import { collection, getDocs } from 'firebase/firestore'

export default {
  name: 'LessonsView',
  components: {
    Lesson
  },
  props: {
    number: { type: String, required: true },
    slide: { type: String, required: true }
  },
  data () {
    return {
      renderComponent: true,
      listaDeS: [],
      lessonActual: this.$route.params.number
    }
  },
  async mounted () {
    // Obtener los datos de la diapositiva actual
    const coleccionDeLessons = collection(db, this.$route.params.number)
    const querycontent = await getDocs(coleccionDeLessons)
    let listaDeSlides = []
    querycontent.forEach((doc) => {
      listaDeSlides.push(doc.data())
    })

    // Guardar la lista de slides en el data
    this.listaDeS = listaDeSlides

    // Cambiar el titulo de la pestaña
    document.title = 'Lessons'
  },
  methods: {
    async actualizarComponente () {
      this.renderComponent = false
      await this.$nextTick()
      this.renderComponent = true
    },
    async moverDiapositiva (direccion) {
      let listaDeSlides = this.listaDeS
      let SlideActual = this.$route.params.slide
     
      let anterior
      let siguiente

      // Recorrer el array de Slides para encontrar el anterior y el siguiente 
      for (let i = 0; i < listaDeSlides.length; i++) {
        if (listaDeSlides[i].slide === SlideActual) {
          anterior = listaDeSlides[i - 1]
          siguiente = listaDeSlides[i + 1]
          break
        }
      }

      // Si no se encontró el anterior o el siguiente, se asigna el primero o el ultimo
      if (anterior === undefined) {
        anterior = listaDeSlides[listaDeSlides.length - 1]
      }
      if (siguiente === undefined) {
        siguiente = listaDeSlides[0]
      }

      // Determinar si se debe redireccionar al siguiente o al anterior
      if (direccion === '<') {
        await this.$router.push({ name: 'lesson', params: { slide: anterior.slide, number: this.$route.params.number } })
      } else {
        await this.$router.push({ name: 'lesson', params: { slide: siguiente.slide, number: this.$route.params.number } })
      }
      
      // Actualizar el componente con los nuevos props
      this.actualizarComponente()
    }
  }
}

</script>

<style scoped>
img {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.moveButton {
  width: 80px;
  height: 80px;
  background-color: #25274F;
  border-radius: 50%;
  border: 0px;
  display: inline-block;
  cursor: pointer;
  color: white;
  text-decoration: none;  
  text-align: center;
  font-size: 18pt;

  /*
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  */
  margin: 20px;
}
.moveButton:hover {
  background-color: #3F51B5;
}

@media (max-width: 1000px) {
  img {
    width: auto;
    height: 10vh;
  }

  .moveButton:hover {
    background-color: #25274F;
  }
}
</style>
