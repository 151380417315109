<template>
    <div v-if="language.includes('es')" class="container">
        <div class="text">   
            Usamos cookies para mejorar tu experiencia en nuestra web. 
            <a href="https://es.funifelt.com/privacy-statement"> Políticas de Privacidad</a>
            <button @click="cerrar" class="buttonAccept">Aceptar</button>
        </div>
    </div>
    <div v-else class="container">
        <div class="text">   
            We use cookies to ensure you get the best experience on our website.
            <a href="https://www.funifelt.com/privacy-statement">Privacy Policies</a>
            <button @click="cerrar" class="buttonAccept">Accept</button>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            language: navigator.language
        }
    },
    methods: {
        cerrar () {
            this.$emit('aceptado')
        }
    }
}
</script>


<style scoped lang="scss">
.container {
    // Parte externa del popup 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 2;
    
    // Popup
    .text {
        border-radius: 30px;
        background-color: white;
        width: 70%;
        padding: 30px;
        font-size: 24pt;
        font-family: Cambay-Regular;
        color: #3B5CAB;   
        position: relative;
    }

    
    .buttonAccept {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;

        height: 40px;
        border: 0px solid;
        background: #2691d9;
        border-radius: 25px;
        font-size: 18px;
        color: white;
        font-weight: 700;
        cursor: pointer;
        outline: none;
        padding: 0 20px;
    }
    .buttonAccept:hover {
        background-color: #3B5CAB;
    }
}

// Resoluciones menores a 1000px
@media (max-width: 1000px) {
    .popup {
        .popup-inner {
            width: 90%;
            font-size: 18pt;
        }
    }
}
</style>
